$(function () {
    // return;
    if ($("#mobile")) {
        $("#mobile").mmenu({
            searchfield: true,
            extensions: ["pageshadow"],
            offCanvas: {
                position: "right",
                zposition: "back"
            },
            navbar: {
                title: false
            },
            navbars: [
                {
                    position: "bottom",
                    content: [$("#mobile .bottom")]
                },
                {
                    position: "bottom",
                    content: [$("#mobile .meta")],
                    height: 4,
                }]
        });
        var API = $("#mobile").data("mmenu");

        $(".navigation--burger").click(function () {
            API.open();
        });

        $(window).resize(function () {
            if ($(window).width() > 991) {
                API.close();
            }
        });
    }
});

$(document).ready(function () {
    $('#mobile').removeClass('hidden-xs-up');
});
