document.addEventListener('DOMContentLoaded', function() {
    const buttons = document.querySelectorAll('.btn-animation');

    const options = {
        root: null, 
        rootMargin: '0px 0px -30% 0px', // triggers when it crosses the bottom 30% of viewport
        threshold: 0
    };

    function handleIntersection(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('btn-animation-play');
                observer.unobserve(entry.target);
            }
        });
    }

    const observer = new IntersectionObserver(handleIntersection, options);

    buttons.forEach(button => {
        observer.observe(button);
    });
});