$(window).on('load', function(){
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;

    var header = $('#header');
    var global = $('#global');
    var sticky = header.find('.sticky');
    var headerHeight = header.outerHeight();
    var stickyHeight = sticky.outerHeight();

    $(window).scroll(function () {
        stickyHeight = sticky.outerHeight();
        didScroll = true;
    });

    $(window).resize(function () {
        stickyHeight = sticky.outerHeight();
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 0);

    function hasScrolled() {
        var st = $(this).scrollTop();
        stickyHeight = sticky.outerHeight();

        var scrollPosition = $(window).scrollTop();
        if (scrollPosition > (headerHeight + 10)) {
            global.addClass('header-sticky').css('padding-top', headerHeight);
            sticky.css('top', '-' + (stickyHeight + 15) + 'px');
            if (Math.abs(lastScrollTop - st) <= delta)
                return;

            if (st > lastScrollTop && st > headerHeight) {
                sticky.css('transform','translateY(0)');
            } else {
                if (st + $(window).height() < $(document).height()) {
                    sticky.css('transform', 'translateY(' + (stickyHeight + 15) + 'px)');
                }
            }
        } else {
            global.removeClass('header-sticky').removeAttr('style');
            sticky.removeAttr('style');
        }
        lastScrollTop = st;
    }
});