$(function () {

    var buttonFixed = $('.button-fixed');
    var buttonFixedIcon = $('.button-fixed--icon');


    var toogleButton = function () {
        buttonFixed.toggleClass('open');
    };


    $('.button-fixed--text').swipe({
        swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
            if(direction === 'left') {
                toogleButton();
            }
        }
    });

    var runButtonFixed = function () {

        $(window).scroll(function (event) {
            var buttonFixedTop = buttonFixed.offset().top;
            var teaser = $('[data-ctype="teaser"]');
            var teaserTop = teaser.offset().top + teaser.height();
            if(buttonFixedTop > teaserTop) {
                buttonFixed.parent().addClass('after-teaser');
                if (buttonFixed.data('type') === 2) {
                    setTimeout(function () {
                        if(! buttonFixed.hasClass('open')) {
                            toogleButton();
                        }
                    }, (buttonFixed.data('timer') * 1000) + 1000);
                }
            }
        });

        $(buttonFixedIcon).click(function () {
            toogleButton();
        });

        if (buttonFixed.data('type') === 1) {
            var buttonFixedCE = $('#c'+ buttonFixed.data('ce'));
            $(window).scroll(function (event) {
                var buttonFixedTop = buttonFixed.offset().top;
                var buttonFixedCETop = buttonFixedCE.offset().top;
                if(buttonFixedTop > buttonFixedCETop) {
                    if(! buttonFixed.hasClass('opened-by-ce')) {
                        if(! buttonFixed.hasClass('open')) {
                            toogleButton();
                            buttonFixed.addClass('opened-by-ce')
                        }
                    }
                }
            });
        }
    };

    if(buttonFixed.length) {
        runButtonFixed();
    }
});