window.bp = {
    // Small screen / phone
    sm: 544,
    // Medium screen / tablet
    md: 768,
    // Large screen / desktop
    lg: 992,
    // Extra large screen / wide desktop
    xl: 1200
};

window.baseUrl = location.protocol + "//" + location.host + '/';
window.imagePath = location.protocol + "//" + location.host + '/typo3conf/ext/in_theme/Resources/Public/Static/';