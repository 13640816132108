$(function () {

    var menu = $('select#quickstart-3');
    var submenu = $('select[id*="subquickstart"]');


    $(menu).change(function() {
        var target = $(this).val();

        $(submenu).hide();
        $('#subquickstart-' + target).show();
    });

    $(submenu).change(function() {
        var uri = $(this).val();
        document.location.href = uri;
    });
});