/**
 * Experience
 */

$('[data-list-type="kluexperience_pi3"]').each(function() {

    $(this).magnificPopup({
        tPrev: 'Previous',
        tNext: 'Next',
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        delegate: 'a.zoom',
        type: 'image',
        image: {
            verticalFit: true
        },
        gallery: {
            enabled:true
        },
        preload: [1,2],
        callbacks: {
            elementParse: function(item) {
                var responsive = '';

                if( $(window).width() < bp.sm ) {
                    responsive =  item.el.attr('data-src-xs');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-xs');
                    }
                }
                else if( $(window).width() < bp.md ) {
                    responsive =  item.el.attr('data-src-sm');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-sm');
                    }
                }
                else if( $(window).width() < bp.lg ) {
                    responsive =  item.el.attr('data-src-md');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-md');
                    }
                }
                else if( $(window).width() < bp.xl ) {
                    responsive =  item.el.attr('data-src-lg');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-lg');
                    }
                }
                else {
                    responsive =  item.el.attr('data-src-xl');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-xl');
                    }
                }
                return item.src = responsive;
            }
        },
        zoom: {
            enabled: true,
            duration: 400,
            easing: 'ease-in-out',
            opener: function(element) {
                return element.find('img');
            }
        },
    });
});


/**
 * FSC
 */

$('.ce-gallery').each(function() {

    $(this).magnificPopup({
        tPrev: 'Previous',
        tNext: 'Next',
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        delegate: 'a.zoom',
        type: 'image',
        image: {
            verticalFit: true
        },
        gallery: {
            enabled:true
        },
        preload: [1,2],
        callbacks: {
            elementParse: function(item) {
                var responsive = '';

                if( $(window).width() < bp.sm ) {
                    responsive =  item.el.attr('data-src-xs');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-xs');
                    }
                }
                else if( $(window).width() < bp.md ) {
                    responsive =  item.el.attr('data-src-sm');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-sm');
                    }
                }
                else if( $(window).width() < bp.lg ) {
                    responsive =  item.el.attr('data-src-md');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-md');
                    }
                }
                else if( $(window).width() < bp.xl ) {
                    responsive =  item.el.attr('data-src-lg');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-lg');
                    }
                }
                else {
                    responsive =  item.el.attr('data-src-xl');
                    if(window.devicePixelRatio > 1) {
                        responsive =  item.el.attr('data-src-retina-xl');
                    }
                }
                return item.src = responsive;
            }
        },
        zoom: {
            enabled: true,
            duration: 400,
            easing: 'ease-in-out',
            opener: function(element) {
                return element.find('img');
            }
        },
    });
});