/**
 * Breadcrumb
 *
 */

$(function () {
    // Variables
    var list = $('.breadcrumb ol > li');
    var links = $('.breadcrumb ol > li');
    var inListLinks = $('.breadcrumb ol li ul a');
    var tapped = false;

    function isDropDown(el) {
        var ul = el.find('ul');
        if (ul.length > 0 && ul.find('li').length > 0) {
            ul.prev().addClass('dropdown-toggle');
        }else{
            ul.remove();
        }
    }

    function columnCount(el) {
        var ul = el.find('ul');
        var lis = ul.find('li');
        if (lis.length >= 8) {
            ul.addClass('column-count');
        }
    }

    list.each(function () {
        isDropDown($(this));
        columnCount($(this));
    });


    /**
     * Single / Double click
     */
    inListLinks.on("touchstart click", function (e) {
        window.location.href = $(this).attr("href");
        e.preventDefault()
    });
    links.on("touchstart", function (e) {
        if (!tapped) { //if tap is not set, set up single tap
            tapped = setTimeout(function () {
                tapped = null;
            }, 1000);
        } else {
            clearTimeout(tapped);
            tapped = null;
            window.location.href = $(this).find('a').attr("href");
        }
        e.preventDefault()
    });
});