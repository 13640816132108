/**
 * Overlay Box
 *
 */

var Overlay = {

    open: function (overlay, overlayContainer, toOpen, videoLink, button) {
        overlay.addClass('open');
        overlayContainer.find('input').focus();

        if (toOpen == 'video') {
            Overlay.videoResizer(overlayContainer);
            var videoID = videoLink.split('v=')[1];
            var ampersandPosition = videoID.indexOf('&');
            if(ampersandPosition != -1) {
                videoID = videoID.substring(0, ampersandPosition);
            }
            $(overlay).find('.embed-responsive').html('<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/' + videoID + '?autoplay=1&rel=0" allowfullscreen></iframe>');
        }

        if(toOpen == 'cvbook') {
            $(overlay).find('.overlay-container-content').html('<iframe src="' + button.data('pdf') + '" width="100%" height="100%" frameborder="0"></iframe>');
        }
    },

    close: function (overlay, toOpen) {
        overlay.removeClass('open');

        if (toOpen == 'video') {
            $(overlay).find('.embed-responsive').empty();
        }

        if(toOpen == 'cvbook') {
            $(overlay).find('.overlay-container-content').empty();
        }
    },

    videoResizer: function (overlayContainer) {
        Overlay.videoChangeClasses(overlayContainer);
        $(window).resize(function(){
            Overlay.videoChangeClasses(overlayContainer);
        });
    },

    videoChangeClasses: function (overlayContainer) {
        if($(window).height() < 700) {
            overlayContainer.find('.row > div').addClass('offset-lg-2 col-lg-8').removeClass('offset-lg-1 col-lg-10');
        } else {
            overlayContainer.find('.row > div').removeClass('offset-lg-2 col-lg-8').addClass('offset-lg-1 col-lg-10');
        }
    }
}

$(function () {
    $('[data-overlay]').each(function () {

        var button = $(this);
        var toOpen = $(this).data('overlay');
        var videoLink = $(this).data('link');
        var overlay = $('[data-overlay-container="' + toOpen + '"]');
        var overlayContainer = overlay.children('.overlay-container');

        $(this).on('click', function (event) {
            event.preventDefault();

            Overlay.open(overlay, overlayContainer, toOpen, videoLink, button);

            $(document).keyup(function (e) {
                if (e.keyCode == 27) Overlay.close(overlay, toOpen);
            });

            $(overlayContainer).find('.close').click(function () {
                Overlay.close(overlay, toOpen);
            });

            $(overlayContainer).find('.overlay-container-content').click(function (event) {
                event.stopPropagation();
            });

            $(overlay).click(function () {
                Overlay.close(overlay, toOpen);
            });
        });
    });
});