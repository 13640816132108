// console.log($('').selectize());

require('./Components/variables.js');
require('./Components/helper');

require('./Components/backdrop.js');

require('./Components/mmenu.js');
require('./Components/menu.js');
require('./Components/breadcrumb');

require('./Components/lightbox.js');

require('./Components/stickyHeader.js');
require('./Components/geolangoverlay');
require('./Components/overlay.js');

require('./Components/footer_map');
require('./Components/buttonanimation.js');



//
// klu_cce
//

require('./../../../../../klu_cce/Resources/Private/Assets/JavaScript/components/teaser');
require('./../../../../../klu_cce/Resources/Private/Assets/JavaScript/components/currency');
require('./../../../../../klu_cce/Resources/Private/Assets/JavaScript/components/button-fixed');
require('./../../../../../klu_cce/Resources/Private/Assets/JavaScript/components/homeelement');