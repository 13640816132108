$(function(){

    var teasers = $(document).find('[data-ctype="teaser_shuffle"]');
    var navteasers = $(document).find('li.navteaser,li.navteaser-slim');

    if(teasers.length) {
        $(teasers[Math.floor(Math.random()*teasers.length)]).removeClass('hidden');
    }

    if(navteasers.length) {
        $(navteasers).each(function() {
            var navteaseritems = $(this).find('.navteaseritem');
            $(navteaseritems[Math.floor(Math.random()*navteaseritems.length)]).removeClass('hidden');
        });
    }

});