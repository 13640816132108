$(function(){

    /**
     * Exchange
     *
     * @param rates
     * @param value
     * @param target
     */
    var exchange = function(rates, value, target) {
        rates.EUR = 1;
        fx.rates = rates;
        value = accounting.unformat(value);
        var convertedValue = fx(value).from('EUR').to(target);

        return accounting.formatMoney(convertedValue, {
            symbol: target,
            format: '%s %v'
        });
    };

    /**
     * Run
     *
     * @param target
     */
    var runExchange = function(target) {
        $.getJSON('/typo3conf/ext/klu_cce/Resources/Public/currency/exchange.json', function(data) {
            var rates = data.rates;
            $('currency').each(function() {
                var element = $(this);
                var value = $(this).data('value');
                var output = exchange(rates, value, target);
                element.addClass('changed');
                element.text(output).attr('data-sign',target);
                return true;
            });
        });
    };

    /**
     * For First Time
     *
     */
    var runForFirstTime = function() {
        $.getJSON('/typo3conf/ext/klu_cce/Resources/Public/currency/exchange.json', function(data) {
            var rates = data.rates;
            $('currency').each(function() {
                var target = 'EUR';
                var element = $(this);
                var value = $(this).text();
                var output = exchange(rates, value, target);
                element.text(output).attr('data-value',value).attr('data-sign',target);
            });
        });
    };
    runForFirstTime();

    /**
     * Run ListJs on currency List
     *
     */
    var options = {
        valueNames: [ 'exchange' ]
    };
    var currencyList = new List('currencyList', options);

    /**
     * Run on click
     *
     */
    $('.exchange').click(function() {
        $('currency').removeClass('changed');
        var target = $(this).data('exchange');
        runExchange(target);
        $('[data-overlay-container="currency"]').removeClass('open'); // close overlay
    });


});