const Cookie = (function () {
    return {
        getAndDecode: function (name) {
            const data = this.get(name);
            if (data) {
                return JSON.parse(atob(data));
            } else {
                return null;
            }
        },
        get: function (name) {
            let nameEQ = name + "=";
            let ca = document.cookie.split(';');

            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1, c.length)
                }
                if (c.indexOf(nameEQ) == 0) {
                    return c.substring(nameEQ.length, c.length)
                }
            }
            return null;
        },
        setAndEncode: function (name, value, days) {
            this.set(name, btoa(JSON.stringify(value)), days);
        },
        set: function (name, value, days) {
            let expires;
            if (days) {
                let date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toGMTString();
            }
            else {
                expires = ""
            }
            document.cookie = name + "=" + value + expires + "; path=/";
        },
        remove: function (name) {
            this.set(name, "", -1);
        }
    }
})();

window.dismissOverlay = function (uid) {
    let COOKIE_NAME = 'dismissedOverlays';
    const cookieData = Cookie.getAndDecode(COOKIE_NAME);

    if (cookieData) {
        if (cookieData.indexOf(uid) === -1) {
            cookieData.push(uid);
            Cookie.setAndEncode(COOKIE_NAME, cookieData, 30);
        }
    } else {
        Cookie.setAndEncode(COOKIE_NAME, [uid], 30);
    }
};

window.removeDismissedOverlays = function () {
    Cookie.remove('dismissedOverlays');
};

(function () {
    const getPreferredBrowserLanguage = function () {
        if (navigator.languages) {
            return navigator.languages[0].substr(0, 2).toUpperCase();
        } else if (navigator.language) {
            return navigator.language.substr(0, 2).toUpperCase();
        } else {
            return '';
        }
    }

    const Overlay = function (target) {
        this.COOKIE_NAME = 'dismissedOverlays';

        this.shoudLoad = function () {
            if(this.target.classList.contains('geo-overlay')) {
                return true;
            } else {
                const preferredLanguage = getPreferredBrowserLanguage();
                return (this.dataId === '' || this.dataId === preferredLanguage);
            }
        }

        // Abstract
        this.onHide = function () { }

        this.show = function () {
            this.target.classList.remove('fadeout');
        }

        this.hide = function () {
            this.target.classList.add('fadeout');
            this.onHide();
        }

        this.dismiss = function () {
            const cookieData = Cookie.getAndDecode(this.COOKIE_NAME);

            if (cookieData) {
                if (cookieData.indexOf(this.uid) === -1) {
                    cookieData.push(this.uid);
                    Cookie.setAndEncode(this.COOKIE_NAME, cookieData, 30);
                }
            } else {
                Cookie.setAndEncode(this.COOKIE_NAME, [this.uid], 30);
            }

            this.hide();
        }

        this.addCloseListener = function () {
            const closeButtons = this.target.querySelectorAll('.close-overlay');

            for (let i = 0; i < closeButtons.length; i++) {
                closeButtons[i].addEventListener('click', function (event) {
                    event.preventDefault();
                    this.dismiss();
                }.bind(this));
            }
        }

        this.initialize = function () {
            this.dataId = target.dataset.overlayData.toUpperCase();
            this.load = this.shoudLoad();
            this.addCloseListener();
            this.uid = parseInt(target.dataset.uid);
        }

        this.target = target;
        this.initialize();
    };

    window.addEventListener('load', function () {
        const chatbotButton = document.querySelector('.chatbot .chatbot__options .chatbot__button');

        function hideChatbot() {
            if (chatbotButton) {
                chatbotButton.classList.add('chatbot__button--blocked');
            }
        }

        function showChatbot() {
            if (chatbotButton) {
                chatbotButton.classList.remove('chatbot__button--blocked');
            }
        }

        const locationOverlayElements = document.querySelectorAll('.geo-overlay');
        const locationOverlayPipeline = [];

        for (let i = 0; i < locationOverlayElements.length; i++) {
            let o = new Overlay(locationOverlayElements[i]);

            if (o.load) {
                locationOverlayPipeline.push(o);
            }
        }

        function finishLocationOverlayPipeline() {
            startLanguageOverlayPipeline();
        }

        const locationOverlayAnimationDuration = 600;

        function startLocationOverlayPipeline() {
            if (locationOverlayPipeline.length > 0) {
                for (let i = 0; i < locationOverlayPipeline.length; i++) {
                    if (i < locationOverlayPipeline.length - 1) {
                        locationOverlayPipeline[i].onHide = function () {
                            window.setTimeout(function () {
                                locationOverlayPipeline[i + 1].show();
                            }, locationOverlayAnimationDuration);
                        };
                    } else {
                        locationOverlayPipeline[i].onHide = function () {
                            window.setTimeout(function () {
                                finishLocationOverlayPipeline();
                            }, locationOverlayAnimationDuration);
                        };
                    }
                }

                locationOverlayPipeline[0].show();
            }
            else {
                finishLocationOverlayPipeline();
            }
        }

        const languageOverlayElements = document.querySelectorAll('.lang-overlay');
        const languageOverlayPipeline = [];

        for (let i = 0; i < languageOverlayElements.length; i++) {
            let o = new Overlay(languageOverlayElements[i]);

            if (o.load) {
                languageOverlayPipeline.push(o);
            }
        }

        function finishLanguageOverlayPipeline() {
            showChatbot();
        }

        const languageOverlayAnimationDuration = 600;

        function startLanguageOverlayPipeline() {
            if (languageOverlayPipeline.length > 0) {
                for (let i = 0; i < languageOverlayPipeline.length; i++) {
                    if (i < languageOverlayPipeline.length - 1) {
                        languageOverlayPipeline[i].onHide = function () {
                            window.setTimeout(function () {
                                languageOverlayPipeline[i + 1].show();
                            }, languageOverlayAnimationDuration);
                        };
                    } else {
                        languageOverlayPipeline[i].onHide = function () {
                            window.setTimeout(function () {
                                finishLanguageOverlayPipeline();
                            }, languageOverlayAnimationDuration);
                        };
                    }
                }

                languageOverlayPipeline[0].show();
            }
            else {
                finishLanguageOverlayPipeline();
            }
        }

        function initializeOverlays() {
            startLocationOverlayPipeline();
        }

        hideChatbot();

        if (Cookie.get('klaro')) {
            initializeOverlays();
        } else {
            let interval = window.setInterval(function () {
                if (Cookie.get('klaro')) {
                    window.clearInterval(interval);
                    initializeOverlays();
                }
            }, 1000);
        }
    });
})();