window.addEventListener("load", (event) => {
    // Hier dann einfach entsprechend die Elemente auswählen
    let link = document.querySelector('.klaro-link');
        
    if(link) {
        if(link.getAttribute("title") === "change_cookie_settings")
        {
            link.addEventListener("click", (event) =>
            {
                event.stopImmediatePropagation();
                event.stopPropagation();
                event.preventDefault();
                klaro.show();
            });
        }
    }
});

function clickTrack(category, action, label) {
    // gtag('event', action, {
    //     'event_category': category,
    //     'event_label': label
    // }
    // );
}

/**
 * detect safari
 */
$(function($) {
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        $('html').addClass('is_safari');
    }
});

/**
 *
 */
$(function($) {
    var input = $('#expertiseSearch');

    $(input).selectize({
        onChange : function (value) {
            $('[data-term]').hide();
            if(value.length) {
                $('#expertiseSearchTerms [data-term]').each(function () {
                    if ($(this).data('term').toLowerCase().includes(value.toLowerCase())) {
                        $(this).show();
                    }
                });
            } else {
                $('[data-term]').hide();
            }
        }
    });
});

/**
 * Ask a Student
 */
$(function($){
    $('#askastudent').html('<iframe class="m-x-auto d-block" id="askastudentiframe" src="https://ssl-account.com/mpohlmann.de/KLU/" width="520px" height="2200px" style="border: 0;"></iframe>');
});

/**
 * Add .unlink for a-tags with images within
 */
$('a').has('img').addClass('unlink');


/**
 * Replace all SVG images with inline SVG
 */
$('img.svg-include').each(function(){
    var $img = $(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    $.get(imgURL, function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find('svg');

        // Add replaced image's ID to the new SVG
        if(typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if(typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass+' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }

        // Replace image with new SVG
        $img.replaceWith($svg);

    }, 'xml');

});


$(".fileuploader").each(function() {
    let fileUploaderContainer = $(this);

    $(fileUploaderContainer).uploadFile({
        url:"/typo3conf/ext/in_theme/Resources/Public/Php/FileUploader/upload.php",
        multiple:true,
        dragDrop:true,
        returnType: "json",
        showDelete: true,
        fileName: "myfile",
        uploadButtonClass: "ajax-file-upload btn btn-gold m-r-1",
        dragDropStr: "<span>Drag & Drop Files</span>",
        deleteCallback: function (data, pd) {
            for (var i = 0; i < data.length; i++) {
                $.post("/typo3conf/ext/in_theme/Resources/Public/Php/FileUploader/delete.php", {op: "delete",name: data[i]},
                    function (resp,textStatus, jqXHR) {
                        $('input[value="' + data[0] + '"]').remove();
                    });
            }
            pd.statusbar.hide();

        },
        onSuccess:function(files,data,xhr,pd)
        {
            let form = fileUploaderContainer.parents('form');
            let marker = $(fileUploaderContainer).data('marker');

            $(data).each(function() {
                $(form).append('<input type="hidden" name="tx_powermail_pi1[field][' + marker +'][]" value="' + this + '" />');
            })
        }
    });
});
