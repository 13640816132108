window.addEventListener('load', function() {
    (function() {
        const latLng = [53.826597, 10.147704];
        const zoom = 3;
        const mapContainer = document.querySelector('#footer-map');

        if(mapContainer && L && L.map) {
            const map = L.map(mapContainer, {
                scrollWheelZoom: false,
                zoomControl: false
            }).setView(latLng, zoom);

            L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
                attribution: 'Daten von <a href="https://www.openstreetmap.org/">OpenStreetMap</a> - Veröffentlicht unter <a href="https://opendatacommons.org/licenses/odbl/">ODbL</a>'
            }).addTo(map);

            map.addControl(new L.Control.FullScreen({ position: 'topright' }));
            map.addControl(new L.Control.Zoom({ position: 'bottomright' }));

            const markerIcon = L.icon({
                iconUrl: 'https://www.klu.org/fileadmin/klu.org/Template/Public/images/solid-pin-black.png',
                shadowUrl: 'https://www.klu.org/fileadmin/klu.org/Template/Public/images/solid-pin-black.png',
            
                iconSize:     [36, 36],
                shadowSize:   [36, 36],
                iconAnchor:   [18, 36],
                shadowAnchor: [18, 36],
                popupAnchor:  [0, 0]
            });

            L.marker([53.5405773, 9.994366899999932], {icon: markerIcon, interactive: false}).addTo(map);
        }
    }());
});