/**
 * Zielgruppennavigation
 */
$(function () {
    $('.top-bar--navigation li.toggle').on('click', 'span.toggle', function(){
        $('.dropdown-menu').click(function(event){
            event.stopPropagation();
        });
        $(this).next('ul').animate({
            left: "0"
        }, 250, function() {
            var wrapper = $(this);
            var back = $(this).find('.toggle-back');
            back.click(function(){
                wrapper.animate({
                    left: "100%"
                }, 250);
            })
        });
    });
});

/**
 * Scrollbar
 */

(function($){
    $('.level-4-menu').each(function(element) {

        $(this).enscroll({
            // showOnHover: true,
            verticalTrackClass: 'scroll-track',
            verticalHandleClass: 'scroll-handle',
            zIndex: 10,
            easingDuration: 100
        });

    });
})(jQuery);


/**
 * Main Menu
 */

$(function () {
    $('.level-2').on('click', '.level-2-toggle', function (event) {

        /**
         * Var
         */
        var parent = $(event.target).parent('.level-2');

        /**
         * Close other
         *
         */
        parent.siblings('.level-2.open').removeClass('open').find('.level-4').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');


        /**
         * Toggle
         */
        if (parent.hasClass('open')) {
            parent.removeClass('open').find('.level-4').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');
            toggleBackDrop(0);
        } else {
            parent.addClass('open');
            toggleBackDrop(1,1040);
        }

        /**
         * Active mode
         */

        if($('.level-4.active').length) {

            var childParentActive = $('.level-4.active').addClass('open');
            var parentpositionActive = childParentActive.parents('.level-2-menu').offset().top;
            var childmenuActive = childParentActive.find('.level-4-menu');
            var childpositionActive = childParentActive.parents('.level-3').offset().top;
            childmenuActive.css('top', parentpositionActive - childpositionActive);
        }

        if($('.level-5-active').length) {
            $('.level-5-active').parents('.level-5').addClass('open').find('.level-5-menu').collapse('toggle');
        }

        /**
         * Level-4
         */

        $('.level-4').off().on('click', '.level-4-toggle', function (event) {
            /**
             * Var
             */
            var childParent = $(event.target).parent('.level-4');

            /**
             * Toggle
             */
            childParent.siblings('.level-4.open').removeClass('open');
            $('.level-2-menu').find('.level-4.open').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');



            if (childParent.hasClass('open')) {
                childParent.removeClass('open');
            } else {
                childParent.addClass('open');

                /**
                 * Position
                 */
                var parentposition = childParent.parents('.level-2-menu').offset().top;
                var childmenu = childParent.find('.level-4-menu');
                var childposition = childParent.parents('.level-3').offset().top;
                childmenu.css('top', parentposition - childposition);
            }
        });

        $('.level-4-menu').on('click', '.toggle-back-4', function (event) {
            $(event.target).parents('.level-4.open').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');
        });


        /**
         * Level-5
         */

        $('.level-5').off().on('click', '.level-5-toggle', function (event) {
            var childParent = $(event.target).parent('.level-5');

            childParent.find('.level-5-menu').collapse('toggle');
            if (childParent.hasClass('open')) {
                childParent.removeClass('open');
            } else {
                childParent.addClass('open');
            }
        });

        // $('.level-5').off().on('click', '.level-5-toggle', function (event) {
        //     /**
        //      * Var
        //      */
        //     var childParent = $(event.target).parent('.level-5');
        //
        //     /**
        //      * Toggle
        //      */
        //     childParent.siblings('.level-5.open').removeClass('open');
        //     $('.level-3-menu').find('.level-5.open').removeClass('open');
        //
        //     if (childParent.hasClass('open')) {
        //         childParent.removeClass('open');
        //     } else {
        //         childParent.addClass('open');
        //     }
        // });
        //
        // $('.level-5-menu').on('click', '.toggle-back-5', function (event) {
        //     $(event.target).parents('.level-5.open').removeClass('open');
        // });




        /**
         * Detect outside click
         */
        $('html').off().on('click', function (event) {
            if (
                !$(event.target).is('.level-2-toggle') && !$(event.target).is('.level-2-menu') && !$(event.target).is('.level-2-menu *') && !$(event.target).is('.level-2')
            ) {
                if (parent.hasClass('open')) {
                    parent.removeClass('open').find('.level-4').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');
                    toggleBackDrop(0);
                }
            }
        });
    });
});