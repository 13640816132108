/**
 * Toggle Backdrop
 * @param con
 * @param z
 */

 window.toggleBackDrop = function(con , z) {
    if (con == 0) {
        var backDrop = $('div.modal-backdrop');
        backDrop.toggleClass("in");
        backDrop.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function (e) {
            $('div.modal-backdrop').remove();
        });
    }
    else {

        if($('div.modal-backdrop').length) {
            $('div.modal-backdrop').addClass('in');
        } else {
            $('<div class="modal-backdrop fade" style="z-index: ' + z + '"></div>').appendTo('#global');
            var backDrop = $('div.modal-backdrop');
            backDrop[0].offsetHeight;
            backDrop.toggleClass("in");
        }
    }
}

/**
 * Top-bar Dropdown
 *
 */

var dropDownTopBar = 'dropdown-topbar';

$('.' + dropDownTopBar).on('show.bs.dropdown', function () {
    toggleBackDrop(1,1044);
});

$('.' + dropDownTopBar).on('hide.bs.dropdown', function () {
    toggleBackDrop(0);
});